.about-section {
  z-index: 1;
  background-color: #f4fafd;
  padding-top: 10%;
  margin-top: -12%;
  padding-bottom: 15%;
  margin-bottom: 1%;
}
.row {
  max-width: 114rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 2%;
}
.col-1 {
  flex-basis: 50%;
  flex-grow: 0;
}
.col-1:first-child {
  margin-left: 6rem;
  margin-right: 4rem;
}

.heading-secondry {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
  background: linear-gradient(
    90deg,
    rgb(103, 161, 243) 25%,
    rgb(38, 101, 188) 48%,
    rgb(57, 135, 244) 76%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0.6rem 1rem 1rem rgba(rgb(7, 4, 95), 0.2);
  margin-bottom: 1%;
  margin-top: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.about-paragraph {
  font-size: 1.4rem;
  text-align: justify;
  font-weight: 500;
  margin: 1rem;
}
.composition {
  position: relative;
  margin-top: 3rem;
}
.composition__photo {
  width: 48%;
  box-shadow: 1rem 2rem 4rem #395369;
  background-color: var(--redR-color);
  border: var(--photo-border) solid 1.5px;
  position: absolute;
  z-index: 10;
  transition: all 0.3s;
  outline-offset: 1.2rem;
  height: 250px;
}

.composition__photo--p1 {
  left: 0;
  top: 0;
}

.composition__photo--p2 {
  right: 4rem;
  top: 4rem;
  z-index: 9;
}

.composition__photo--p3 {
  left: 20%;
  top: 9rem;
}

.composition__photo--p4 {
  top: -3rem;
  left: 30%;
  z-index: 8;
}

.composition__photo:hover {
  transform: scale(1.2) translateY(-0.5rem);
  outline: 10px solid var(--photo-border);
  z-index: 20;
}

.composition:hover .composition__photo:not(:hover) {
  transform: scale(0.9);
}
/* ///////////////////////////////////////// Mobile and Tablet design//////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1200px) {
  .about-section {
    text-align: center;
    line-height: 3rem;
  }

  .row {
    flex-direction: column;
    width: 90vw;
    height: 70%;
    display: flex;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0;
  }

  .col-1:first-child {
    margin: 1rem;
    padding: 0 1rem 0 1rem !important;
  }

  .col-1:last-child {
    padding: 1rem 0 5rem 0;
    margin-bottom: 1rem;
  }
  .composition {
    width: 100%;
  }

  .composition__photo {
    width: 55vw;
    position: absolute;
    height: 45vh;
    margin: 1%;
  }

  .composition__photo--p1 {
    left: 1vw;
    top: 10vh;
    z-index: 9;
  }

  .composition__photo--p2 {
    left: 33vw;
    top: 15vh;
    z-index: 10;
  }

  .composition__photo--p3 {
    left: 18vw;
    top: 26vh;
    z-index: 11;
  }

  .composition__photo--p4 {
    top: 0;
    left: 22vw;
    z-index: 8;
  }

  .composition__photo--p1:hover {
    transform: scale(1.2) translateY(-0.5rem) translateX(3rem);
    outline: 10px solid var(--photo-border);
    z-index: 20;
  }
  .composition__photo--p2:hover {
    transform: scale(1.2) translateY(-0.5rem) translateX(-6rem);
    outline: 10px solid var(--photo-border);
    z-index: 20;
  }
  .composition__photo--p3:hover {
    transform: scale(1.2) translateY(-5rem);
    outline: 10px solid var(--photo-border);
    z-index: 20;
  }
  .composition__photo--p4:hover {
    transform: scale(1.2) translateY(-0.5rem);
    outline: 10px solid var(--photo-border);
    z-index: 20;
  }

  .composition:hover .composition__photo:not(:hover) {
    transform: scale(0.9);
  }
}
/*/////////////////////////////////////////////////////////////////////// Tablet design////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (min-width: 601px) and (max-width: 1200px) {
  .heading-secondry {
    font-size: 2.5rem;
  }

  .about-paragraph {
    font-size: 1.8rem;
    margin-left: 4rem;
  }

  .about-section {
    padding-top: 8rem;
    margin: -20% 0 5% 0;
    padding-bottom: 5rem;
    height: fit-content;
  }

  .composition {
    height: 70vh;
    margin-bottom: 7rem;
    padding-bottom: 7rem;
  }
  .heading-secondry {
    margin-top: 2%;
    padding-top: 1%;
  }
}
@media screen and (min-width: 801px) and (max-width: 1200px) {
  .about-section {
    padding-top: 14rem;
  }
  .heading-secondry {
    padding-top: 3rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) and (orientation: landscape) {
  .heading-secondry {
    margin-top: 3rem;
  }

  .composition__photo {
    width: 50vw;
    position: absolute;
    height: 35vh;
  }
}
@media screen and (min-width: 601px) and (max-width: 800px) and (orientation: landscape) {
  .heading-secondry {
    margin-top: 4rem;
  }
}
/*/////////////////////////////////////////////////////////////// Small screen (M0bile)///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 600px) {
  .about-section {
    margin: -10% 0 20% 0;
    padding: 6rem 1rem 5rem 1rem;
    height: fit-content;
  }

  .heading-secondry {
    font-size: 2.2rem;
  }

  .composition__photo {
    width: 55vw;
    position: absolute;
    height: 30vh;
  }
  .composition {
    height: 50vh;
  }
  .about-paragraph {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 350px) {
  .about-section {
    padding-top: 12rem;
    margin: -25% 0 5% 0;
    padding-bottom: 6rem;
    height: fit-content;
  }
  .heading-secondry {
    margin-top: 0.5%;
    padding-top: 0;
    font-size: 1.8rem;
  }
}
/*/////////////////////////////////////////////////////////////////////////  Big Screen////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (min-width: 1800px) {
  .about-section {
    padding-bottom: 14%;
    margin-bottom: 1%;
    height: fit-content;
  }
  .heading-secondry {
    margin-top: 6%;
  }
  .col-1:last-child {
    padding: 0 0 0 4rem;
    margin-right: 10rem;
    margin-left: 10rem;
  } 

  .col-1:first-child {
    margin: 0.2rem 1rem 0 10rem;
  }
  .composition__photo {
    width:55%;
   }
   .composition__photo--p2 {
    right: 2rem;
   }
  
}
