header {
  height: 75vh;
  position: relative;
  background-color: var(--B-logo-clor);
  background-image: linear-gradient(
      178deg,
      rgba(229, 237, 242, 0.6) 18%,
      rgba(133, 179, 231, 0.7) 35%
    ),
    url(../../assets/pic/header/manager.PNG);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
  clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
}
.text-box-main {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.textbox-sub {
  position: absolute;
  left: 50%;
  top:40%;
  transform: translate(-50%, -50%);
  padding:2rem 0 2rem 0;
  margin-top:6%;
}
.heading-primary-R {
  display: inline;
  font-size: 10rem;
  font-weight: 500;
  padding-right: 1rem;
  padding-top: 1rem;
  color: var(--redR-color);
  text-shadow: 4px 6px 4px var(--logo-color);
}
.heading-primary-B {
  display: inline;
  font-size: 10rem;
  font-weight: 500;
  padding-right: 15px;
  text-shadow: 4px 6px 4px var(--logo-color);
}
.heading-primary-C {
  display: inline;
  font-size: 10rem;
  font-weight: 500;
  color: var(--B-logo-clor);
  text-shadow: 4px 6px 4px var(--logo-color);
}

.heading-primarySub {
  display: inline-block;
  font-size: 2rem;
  font-weight: bolder;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  background-image: linear-gradient(
    90deg,
    rgb(217, 231, 253) 10%,
    hsl(0, 0%, 100%) 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: cover;
  padding-top:1rem;
}
.heading-secondrySub {
  display: block;
  margin-top: 1.5rem;
  white-space: nowrap;
  line-height: 0.5rem;
  padding-bottom: 1rem;
}
/* /////////////////////////////////////////////////////////mpbile design///////////////////////////////////////////// */
@media screen and (max-width: 600px) {
   header {
    height: 40vh;
    margin-top: -2%;
    width: 100vw;
    background-size: 85%;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    margin:-2% 0 0 0;
    padding:0;
  }
  .heading-primary-R,
  .heading-primary-B,
  .heading-primary-C {
    font-size: 8rem;
  }
  .heading-primarySub {
    font-size: 1.8rem;
   }
  .heading-secondrySub {
    font-size:1.8rem;
  }
  .textbox-sub{
    margin-top: 12%;
  }
}
@media screen and (max-width: 600px) and (orientation: landscape) {
  header {
    height:70vh;
    margin-top: -2%;
    width: 100vw;
    background-size: 85%;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    margin:-2% 0 0 0;
    padding:0;
  }
  .heading-primary-R,
  .heading-primary-B,
  .heading-primary-C {
    font-size:8rem;
  }
  .heading-primarySub {
    font-size:1.8rem;
  }
   .heading-secondrySub {
    font-size:1.8rem;
   } 
   .textbox-sub{
    margin-top: 10%;
  }
}
@media screen and (min-width:601px) and (max-width: 1200px) and (orientation: landscape) {
  header {
    height: 75vh !important;
     width:100vw;
    background-size: 85%;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%) !important;
    padding-top:2%;
    margin-top:-2%;
    overflow-y: scroll;
  }
  .text-box-main{
    top:45%
  }
  .textbox-sub{
    margin-top: 12% !important;
  }

  }
  @media screen and (min-width:601px) and (max-width:850px)  {
    .textbox-sub{
      margin-top: 15% !important;
    }
  
    
  }
@media screen and (min-width: 601px) and (max-width:800px) {
  header {
    height: 60vh;
    margin-top:-6%;
    background-size:90%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 90%);
    }
  .heading-primary-R, .heading-primary-B, .heading-primary-C{
    font-size:8rem;
  }
  .heading-primarySub {
    font-size:1.8rem;
    
  }
  .heading-secondrySub {
    font-size:1.8rem;

  }
  .textbox-sub{
    margin-top: 10%;
  }
}
@media screen and (min-width:801px) and (max-width: 1200px) {
  header{
    margin-top:-2%;
  }
  .heading-primary-R,
  .heading-primary-B,
  .heading-primary-C {
    font-size:10rem;
  }
  .heading-primarySub {
    font-size:2rem;
  }
   .heading-secondrySub {
    font-size:2rem;
   } 
   .textbox-sub{
    margin-top:8%;
  }

}

  @media screen and (min-width: 1800px) {
   header{
     height:75vh;
   }
   .heading-primary-R, .heading-primary-B, .heading-primary-C{
    font-size:12rem;
  }
  .heading-primarySub {
    font-size:2.2rem;

  }
  .heading-secondrySub {
    font-size:2.2rem;
    
  }
  .textbox-sub{
    margin-top:5%;
  }
      }

