.certificate-section {
  height: 30rem;
  margin-bottom: 3%;
  margin-top: -18%;
  padding-bottom: 0;
  position: relative;
  z-index: -1;
}
.certificate {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  top: 40%;
  margin-bottom: 2%;
  padding-bottom: 2%;
}
.card-a {
  flex-basis: 30%;
  flex-grow: 0;
  margin-left: 18%;
  margin-right: 2%;
  padding-right: 2%;
}
.card-a__pic {
  background-image: url("../../assets/pic/certificate/licence.jpg");
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  margin-right: 2%;
  margin-top: 5%;
  margin-left: 8%;
}
.card-b {
  flex-basis: 30%;
  flex-grow: 0;
  margin-right: 18%;
  margin-left: 2%;
}

.card-b__pic {
  background-image: url("../../assets/pic/certificate/ontarion.jpg");
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  margin-top: 5%;
  margin-left: 8%;
}
.heading-secondry--certificate {
  top: 20%;
  position: absolute;
  left: 3%;
  font-size: 4rem;
}
@media screen and (max-width: 1300px) {
  .certificate-section {
    margin-top: -20%;
    height: 25rem;
  }
  .heading-secondry--certificate {
    align-content: center;
  }
  .certificate {
    height: 50%;
    top: 45%;
  }
  .card-b {
    flex-basis: 40%;
    flex-grow: 0;
    margin-right: 8%;
    margin-left: 2%;
  }
  .card-a {
    flex-basis: 40%;
    flex-grow: 0;
    margin-left: 8%;
    margin-right: 2%;
    padding-right: 2%;
  }
}
@media screen and (min-width: 601px) and (max-width: 1300px) and (orientation: landscape) {
  .heading-secondry--certificate {
    padding-top: 2rem;
    font-size: 3rem;
    top: 2%;
  }
  .certificate-section {
    margin-top: -10%;
    height: 25rem;
  }
}
@media screen and (max-width: 601px) {
  .heading-secondry--certificate {
    margin-left: 2%;
    padding-top: 0.5rem;
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 601px) and (max-width: 1300px) {
  .heading-secondry--certificate {
    margin-left: 0.1%;
    padding-top: 1rem;
    font-size: 3rem;
  }
}
@media screen and (min-width: 1800px) {
  .certificate-section {
    height: 40rem;
  }
  .heading-secondry--certificate {
    top: 10%;
  }
  .certificate {
    top:45%;
  }
}
