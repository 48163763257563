.footer-feature {
  height: fit-content;
  background: linear-gradient(
    90deg,
    rgb(35, 64, 105) 20%,
    rgb(8, 15, 48) 38%,
    rgb(11, 70, 125) 55%
  );
  padding-top: 0.6%;
  padding-left: 0;
  padding-right: 0;
  color: var(--footer-font);
  font-size: 1.3rem;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
  line-height: 2rem;
}
.footer-container {
  display: flex;
  flex-direction: row;
  flex-wrap:nowrap;
  width: 100%;
}
.footer-flex {
  flex-basis: 35%;
  flex-grow: 0;
  margin:1% 5% 1% 5%;
}
.footer-about--logo {
  width: 9rem;
  height: 3.5rem;
  border: var(--B-logo-clor) 1px solid;
  border-radius: 6%;
  margin-right: 60%;
  margin-top: 2%;
}
.footer---paragraph {
  padding-top: 0.2%;
  text-align: left;
}

.footer-ContactColumn {
  text-transform: capitalize;
  text-align: left;
  padding-left:2%;
}
.footer-ContactColumn--contactUs {
  font-size: 1.3rem;
  padding-bottom: 2%;
  font-weight: bolder;
  margin-top: 2%;
}

.footer-ContactColumn--titleRow {
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 0.2rem;
}

.footer-ContactColumn--link {
  margin:0 auto;
}
.footer-ContactColumn--p {
  font-size: 1.1rem;
}
.footer-icon {
  margin-top: 1rem;
  margin-left:1rem;
  margin-bottom:2%;
  padding-bottom:2%;
  width: 4rem;
  height: 4rem;
}
.footer-QrCode {
  padding-left:0;
  background:"white";
  width: 100%;
}
.footer-QrCode--titleRow{
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 0.2rem;
  text-transform: capitalize;
}
/* ///////////////////////////////////////Mobile design/////////////////////////////// */
@media screen and (max-width: 780px) {
  .footer-container {
    flex-direction: column;
    line-height: 2rem;
    left: 0;
   }
  .footer-feature {
    height: fit-content;
    font-size:1.3rem;

   }
  .footer-about--logo {
    width: 12rem !important;
    height: 5rem !important;
    margin:2% 5% 2% 0 !important;
    }
  .footer-about--paragraph {
    padding-top: 2%;
    text-align: left;

  }
  .footer-ContactColumn{
    text-align: left;
  }

  .footer-ContactColumn--link{
    display: flex;
    justify-content: center;
  }
  .footer-icon{
    margin-bottom:1rem;
    padding-bottom:1rem;
  }
}
@media screen and (max-width:1800px) {
  .footer-feature{
   line-height:2.2rem;
  }
  .footer-about--logo {
    width:10rem;
    height:4rem;
    margin-right:70%;
  }
  .footer-QrCode--titleRow{
    font-size: 1.3rem;
  }

}
@media screen and ( min-width:781px) and (max-width:1200px){
  .footer-flex {
    flex-basis:45%;
    margin:1% .5% 1% 4.5%;
  }

}