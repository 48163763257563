:root {
  --logo-color: #333333;
  --nav-font: #d2e2e2ef;
  --active-menu: #fcfcfb;
  --toggle-menu:#EFF8FF;
  --B-logo-clor: #0369b9;
  --redR-color: #d82063;
  --about-backcolor: #dde1e1;
  /* --about-backcolor: #f4f7f9; */
  --menu-font: #e8dddd;
  --footer-font: rgb(221, 232, 249);
  --photo-border: #427eb7;
  --service-back: #369b9;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Lato", sans-serif;
  line-height: 2.2em;
 
}
@media screen and (min-width:450px) and ( max-width: 600px) {
  body{
    padding:0;
    margin:0;
  }
  html {
    font-size:65%;
  }  
}
@media screen and ( max-width:450px) {
 
 html{
  font-size:50%;
  } 
 body{
  padding:0;
  margin: 0;
}
}
@media (min-width: 601px) and (max-width: 1350px){
  body{
    padding:0;
    margin: 0;
  }
  html {
    font-size:75%;
  }  
}



