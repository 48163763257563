 
.main-navigation {
    position: sticky;
    position: -webkit-sticky;
    background-color: rgb(11, 70, 125);
    left: 0;
    top: 0;
    text-transform: capitalize;
    z-index: 2000;
    height: 7rem;
  }
  .navigationMobile {
    display: none;
  }
  .navigation-list {
    max-width: 100%;
    height: 6rem;
    border-radius: 2%;
  }
  .navigation-item {
    list-style-type: none;
    display: inline-block;
    margin-right: 1.2rem;
    font-size: 1.6rem;
    margin-top:1.5rem;
   
  }
  .navigation-item:first-child {
    margin-left:30%;
  }
  .navigation-link {
    display: inline-block;
    border-radius: 5%;
    border: var(--active-menu);
    color: var(--nav-font);
    width: 7rem;
    height: 3rem;
    font-size: 2 rem;
    font-weight: 700;
    text-decoration: none;
    padding-top: .4rem;
    padding-bottom: 1rem;
    transition: transform 0.5s ease;
  }
  .main-navigation a:hover {
    background-color: var(--active-menu);
    color: rgb(11, 70, 125);
    transform: scale(1.05) translatex(-0.1rem) translateY(-0.2rem);
  }
  
  .logo-box {
    position: absolute;
    top:1.2rem;
    left: 2.5rem;
    padding-top:0rem;
    height: 5rem;
    width:12rem;
  }
  .logo {
    height:4.5rem;
    width:10rem;
    border-radius: 5%;
    box-shadow: 3px 3px 3px --logo-color;
  }
/* ////////////////////////////////// Large Screen/////////////////////////////// */
@media screen and (min-width:1800px){
  .navigation-item:first-child {
    margin-left:50%;
  }
  .navigation-item{
    font-size: 1.6rem;
  }
  .logo-box {
    top:1rem;
    left: 2.5rem;
    padding-top:0rem;
    height: 6rem;
    width:12rem;
  }
  .logo {
    height:5rem;
    width:11rem;
    border-radius: 5%;
    box-shadow: 3px 3px 3px --logo-color;
  }
  
}