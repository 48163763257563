/*                                  Based for smal screen                                  */
@media screen and (max-width: 1200px) {
 .main-navigation {
    display: none;
  }
 .navigationMobile-checkbox {
    display: none;
  }
  .navigationMobile {
    display: inline-block;
    position:sticky;
    width: 100vw;
    height:8rem;
    top: 0;
    right: 0;
    background-color: rgb(11, 70, 125);
    margin: 0;
    padding: 0;
    z-index:20000;
  }
  .navigationMobile-background {
    border-radius: 50%;
    position: fixed;
    background-image: radial-gradient(
      circle,
      rgba(3, 105, 185, 1) 30%,
      rgb(125, 179, 241) 76%
    );
    box-shadow: 0.5rem .5rem 2rem var(--nav-font);
    z-index: 2000;
    transition:transform 0.8s cubic-bezier(0.88, -0.47, 0.18, 1.01);
  }
  .navigationMobile-button {
    background-color: var(--toggle-menu);
    border-radius: 50%;
    position: fixed;
    z-index: 2500;

  }
  .navigationMobile-nav {
    height: 100vh;
    width: 100vw;
    position:fixed;
    top: 0;
    right: 0;
    z-index:2200;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.88, -0.47, 0.18, 1.01);
    transform:scale(0);
  }
  .navigationMobile-list {
    margin:2%;
    position: absolute;
    top: 50%;
    right:50%;
    transform: translate(50%, -50%);
    text-align: center;
    list-style: none;
  }
  .navigationMobile-link {
    display: inline-block;
    color:white;
    text-decoration: none;
    text-transform: capitalize;
    background-image: linear-gradient(
      120deg,
      transparent 0%,
      transparent 50%,
      var(--about-backcolor) 50%
    );
    background-size: 230%;
    transition: all 0.5s;
    font-size:0.8rem;
    font-weight: 400;
    padding: 1rem;
    line-height: 2rem;
  }
  .navigationMobile-link:hover,
  .navigationMobile-link:active {
    background-position: 100%;
    color: var(--B-logo-clor);
    font-weight: bold;
    transform: translateX(1rem);
  }
  .navigationMobile-item{
    margin: 4rem;
  }
  .navigationMobile-checkbox:checked ~ .navigationMobile-nav {
    opacity: 1;
    width: 100%;
    transform:scale(1);
  }
  .navigationMobile-checkbox:checked ~ .navigationMobile-background {
    transform: scale(100);
  }
  .logo-box {
    position: fixed;
    z-index: 2500;
  }
  .menu__bar {
    display: block;
    position: absolute;
    transition: top 0.4s ease-out, transform 0.4s ease-out;
    background-color: #08083e;
  }
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                       Big Mobile and Tablet (Landscape)                                      */


@media screen and (max-width:1200px) and (orientation:landscape) {
  .navigationMobile{
      height:9rem;
  }
.navigationMobile-background {
  height:4.8rem;
  width:4.8rem;
  top:2.4rem;
  right:2%;
}
.navigationMobile-button {
  height: 6.5rem;
  width: 6.5rem;
  right: 1.2%;
  top: 1rem;
}
/* /// after click on menu or checkbox /////////////////// */
.logo-box {
  top: 2rem;
  left: 1.5%;
  width: 14rem;
  height:7rem;
  position: fixed;
  z-index: 2500;
}
.logo {
  width: 14rem;
  height:5rem;
  margin: 0;
} 
/*                                       Made hamburger menu                                     */
.menu__bar {
  left: 1rem;
  width: 4.5rem;
  height: 0.25rem;
  &:nth-child(1) {
    top: 2rem;
  }
  &:nth-child(2) {
    top: 3.25rem;
  }
  &:nth-child(3) {
    top: 4.5rem;
  }
}
.navigationMobile-list {
  top: 40%;
  right:50%;
  transform: translate(50%, -50%);   
}
.navigationMobile-link{
  font-size:2.8rem !important;
  background-size: 250% !important;
  padding: .5rem !important;
  line-height: 1.2rem !important;
}

.navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar {
  &:nth-child(1) {
    top: 3.25rem;
    transform: scale(1.1) rotate(45deg);
    height: 0.2rem;

  }

  &:nth-child(2) {
    transform: translateX(100%) scale(0);
  }

  &:nth-child(3) {
    top: 3.25rem;
    transform: scale(1.1) rotate(-45deg);
    height: 0.18rem;
  }
}
.navigationMobile-checkbox:checked ~ .navigationMobile-nav {
  opacity: 1;
  width: 100%;
  transform:scale(0.8);
}

} 
@media screen and (max-width:600px) and (orientation:landscape) {
  .navigationMobile{
      height:8rem;
  }
} 

/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
                            Big   Mobile                                        */

@media screen and (min-width:351px) and (max-width: 600px) {
  .navigationMobile-background {
    height: 3.2rem;
    width: 3.2rem;
    top: 1.75rem;
    right:4%;
  }
  .navigationMobile-button {
    height: 4.5rem;
    width: 4.5rem;
    right: 3%;
    top: 1.5rem;
  }
  .navigationMobile-link {
    font-size: 2.4rem !important;
    font-weight: 400;
    padding: 0.8rem;
    line-height: 1.2rem;
  }
  /* /// after click on menu or checkbox /////////////////// */
  .logo-box {
    top: 1.75rem;
    left: 1.5%;
    width: 10rem;
    height: 4rem;
    position: fixed;
    z-index: 2500;
  }
  .logo {
    width: 12rem;
    height: 4rem;
    margin: 0;
  }
  .navigationMobile-list{
    top:40%;
  }
  /* 
                                made togle menu icon  */
  .menu__bar {
    left: 1rem;
    width: 2.5rem;
    height: 0.2rem;
  }
    .menu__bar:nth-child(1) {
      top: 1.5rem;
    }
    .menu__bar:nth-child(2) {
      top: 2.25rem;
   
    }
    .menu__bar:nth-child(3) {
      top: 3rem;
    }
    .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(1) {
      top: 2.3rem;
      transform: scale(1.4) rotate(45deg);
      -webkit-transform:scale(1.4) rotate(45deg);
      -ms-transform:scale(1.4) rotate(45deg);
      height: 0.14rem;
    }
    .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(2){
      transform: translateX(100%) scale(0);
      -webkit-transform:translateX(100%) scale(0) ;
      -ms-transform:translateX(100%) scale(0);
    }
    .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(3) {
      top: 2.3rem;
      transform: scale(1.4) rotate(-45deg);
      -webkit-transform:scale(1.4) rotate(-45deg);
      -ms-transform:scale(1.4) rotate(-45deg);
      height: 0.14rem;
    }
  
  }
  /* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                      Tablet Design                                                            */
  @media screen and (min-width:601px) and (max-width:1200px) {
    .navigationMobile{
        height:10rem;
    }
    .navigationMobile-nav{
      position:fixed;
    }
  .navigationMobile-background {
    height:5rem;
    width:5rem;
    top:2rem;
    right:2%;
    position: fixed;
  }
  .navigationMobile-button {
    height: 6.8rem;
    width: 6.8rem;
    right: 1.2%;
    top: 1rem;
    position:fixed;
  }
  .navigationMobile-link {
    font-size:2.8rem;
    font-weight: 400;
    padding: 1rem;
    line-height: 2.5rem;
  }
  
  /* /// after click on menu or checkbox /////////////////// */
  .logo-box {
    top: 2rem;
    left: 1.5%;
    width: 16rem;
    height:8rem;
    position: fixed;
  }
  .logo {
    width: 15rem;
    height:6rem;
    margin: 0;
  } 
  /*                                        /made togle menu icon/                           */
  .menu__bar {
    left: 1rem;
    width: 4.7rem;
    height: 0.25rem;}

    .menu__bar:nth-child(1) {
      top: 2.4rem;
    }
    .menu__bar:nth-child(2) {
      top:3.4rem;
    }
    .menu__bar:nth-child(3) {
      top: 4.4rem;
    }
    .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(1) {
        top: 3.4rem;
        transform: scale(1.1) rotate(45deg);
        -webkit-transform: scale(1.1) rotate(45deg);
        -ms-transform: scale(1.1) rotate(45deg);
        height: 0.18rem;
      }
  
      .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(2){
        transform: translateX(100%) scale(0);
        -webkit-transform:translateX(100%) scale(0);
        -ms-transform:translateX(100%) scale(0);
      }
  
      .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(3) {
        top: 3.4rem;
        transform: scale(1.1) rotate(-45deg);
        -webkit-transform: scale(1.1) rotate(-45deg);
        -ms-transform: scale(1.1) rotate(-45deg);
        height: 0.18rem;
      }
    }
  
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                        Small Mobile       */
  @media screen and (max-width:350px) {
    .navigationMobile-nav {
      height:70vh;
      width: 60vw;
      top: 0;
      left: 0;
      }
    .navigationMobile-background {
      height:4.2rem;
      width:4.2rem;
      top:2.1rem;
      right:5%;
      box-shadow: 0.1rem 0.4rem 1rem var(--nav-font);
    }    
    .navigationMobile-button {
      height: 4.5rem;
      width: 4.5rem;
      right:4.5%;
      top:2rem;
    }  
    .navigationMobile-link {
      font-size: 2.2rem;
      padding:0.4rem;
     }
    .logo-box {
      top: 2.2rem;
      width: 12rem;
      height: 4rem;
    }
    .navigationMobile-list {
      top:60%;
      transform: translate(50%, -50%);
    }
    .logo {
      width: 12rem;
      height: 4rem;
      margin: 0;
    }
  /*////////////////////////////////////////////made togle menu icon////////////////////////////////////////////////////////*/
  .menu__bar {
      left: 1rem;
      width: 2.5rem;
      height: 0.2rem;
      }
      .menu__bar:nth-child(1) {
        top: 1.5rem;
      }
      .menu__bar:nth-child(2) {
        top:2.25rem;
      }
      .menu__bar:nth-child(3) {
        top: 3rem;
      }
      .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(1) {
          top: 2.25rem;
          transform: scale(1.1) rotate(45deg);
          -webkit-transform:scale(1.1) rotate(45deg);
          height: 0.18rem;
        }
        .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(2){
          transform: translateX(100%) scale(0);
          -webkit-transform:scale(1.1) rotate(45deg);
        }
      .navigationMobile-checkbox:checked + .navigationMobile-button .menu__bar:nth-child(3) {
          top: 2.25rem;
          transform: scale(1.1) rotate(-45deg);
          -webkit-transform: scale(1.1) rotate(-45deg);
          height: 0.18rem;
       }
  }
  

  