.service-section {
  height: 75rem;
  margin-bottom: 3%;
  padding-bottom:3%;
  padding-top:1% ;
  margin-top:-1%;
  overflow: hidden;
  background-color: rgb(3, 3, 30);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 80%);
  position: relative;
  z-index: 1;
}
.part {
  position: relative;
  transition: all 0.8s ease;
  background-color: var(--about-backcolor);
  opacity: 0.8;
  box-shadow: 20px 20px 20px #2c3c65;
  border: var(--photo-border) solid 2px;
  border-radius: 3%;
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom:2rem;
  width: 114rem;
  flex-basis: 25%;
  flex-grow: 0;
  margin-left:2rem;
  padding:2rem 0 2rem 0;
}
.part:hover {
  transform: scale(1.08) translateY(-1.2rem);
  box-shadow: none;
}
.part:last-child{
  margin-right:2rem;
}

.part__pic {
  height:40rem;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 78%);
  background-size:contain;
  background-position:center;
  background-repeat: no-repeat;
  }

.part__pic--img1 {
  background-image: url(@assets/pic/srvices/servicepanel.JPG
  ),
    linear-gradient(
      90deg,
      rgba(220, 239, 255, 1) 25%,
      rgba(202, 231, 255, 1) 41%,
      rgba(223, 236, 252, 1) 53%
    );
}

.part__pic--img2 {
  background-image: url(@assets/pic/srvices/charger.JPG),
    linear-gradient(
      90deg,
      rgba(220, 239, 255, 1) 25%,
      rgba(202, 231, 255, 1) 41%,
      rgba(223, 236, 252, 1) 53%
    );
    background-position:center;
    background-size:cover;
}
.part__pic--img3 {
  background-image: url(@assets/pic/srvices/led.JPG),
    linear-gradient(
      90deg,
      rgba(220, 239, 255, 1) 25%,
      rgba(202, 231, 255, 1) 41%,
      rgba(223, 236, 252, 1) 53%
    );
}

.part__pic--img4 {
  background-image: url(../../assets/pic/srvices/fuses.JPG),
    linear-gradient(
      90deg,
      rgba(220, 239, 255, 1) 25%,
      rgba(202, 231, 255, 1) 41%,
      rgba(223, 236, 252, 1) 53%
    );
  }
.part__heading {
  top: 85%;
  width: 100%;
  position: absolute;
  font-size:1.4rem;
  line-height:2.2rem;
}

canvas {
  filter: blur(0.6px);
  position: absolute;
  top: 10%;
  visibility: visible;
}

.part__heading--p {
  color: var(--service-back);
  font-size: var(--service-font);
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
}

.heading-secondry--service {
  font-size: 5rem;
  text-shadow: 1.5rem 1rem 1.5rem #5a637a;
  background: linear-gradient(
    90deg,
    rgba(240, 248, 255, 1) 40%,
    rgba(176, 220, 255, 1) 53%,
    rgba(176, 220, 255, 1) 68%
  );
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-top: 1%;
  padding-top: 2%;
  }

/* ///////////////////////////////////// FLEX for SERVICES/////////////////////////////////////////////////// */
.service {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
  margin-left:2rem;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom:2rem;
  z-index: 1;
}

/* //////////////////////////////////////////////firefly animation/////////////////////////////////// */
.firefly {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .service-section {
    height: fit-content;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
    margin: -25% 0 0 0;
    padding: 2% 0 30% 0;
    z-index: 50;
  }
  .heading-secondry--service {
    padding-top: 4%;
    padding-bottom:1rem;
    margin-top: 5%;
    }
  .service {
    margin: 10% 0 0 0;
    flex-direction: column;
    width:100vw;
    overflow: hidden;
    
  }
  .part {
    width: 80%;
    margin:5% 10% 5% 10%;
   }
   .part__pic {
    height: 60rem;
    }
  .part__heading--p {
    color: var(--service-back);
    font-size: 2rem;
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
    line-height:4rem;
  }

  canvas {
    filter: blur(0.01px);
    position: absolute;
    height: 200rem;
    visibility: visible;
    overflow: hidden;
  }
}

@media screen and (max-width:600x) {
  /* .part{
    flex-basis:25rem;
   } */
  /* .heading-secondry--service {
    padding-bottom: 1.1rem !important;
  } */
.part:last-child{
  margin-bottom:10%;
}
.part:first-child{
  padding-top:6%;
}
.part__heading {
  top: 80%;
}

/* .part__heading--p {
  font-size: 1.5rem;
  font-weight: 500;   
} */
.part:hover {
  transform: scale(1.03) translateY(-0.8rem);
  box-shadow: none;
}
.service{
  padding:1%;
}
}
@media screen and (min-width:800px) and (max-width:1200px) and (orientation:landscape){
  .service {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    flex-shrink:1;
    margin-top:2%;
   }
   .part {
    flex-basis:40%;
    margin:1% 5% 1% 5%;
    }
    .service-section{
      padding:2% 0 10% 0;
    }
  }
  @media screen and (min-width:1800px) {
    .service{
      margin-left:4%;
      margin-right:4%;
    }
    
  }



